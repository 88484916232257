var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "explain" }, [
    _c("header", { staticClass: "title" }, [_vm._v("存料结算")]),
    _vm._m(0),
    _c("div", { staticClass: "checkMarket", on: { click: _vm.checkMarket } }, [
      _vm._v(" 查看行情 ")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "icon" }),
      _c("h3", [_vm._v("结算申请提交成功")]),
      _c("div", { staticClass: "text" }, [
        _vm._v("我们将尽快为您审核,请留意短信通知")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }