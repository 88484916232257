<template>
  <div class="explain">
            <header class="title">存料结算</header>
            <div class="content">
                <div class="icon"></div>
                <h3>结算申请提交成功</h3>
                <div class="text">我们将尽快为您审核,请留意短信通知</div>
            </div>
    <div class="checkMarket" @click="checkMarket">
      查看行情
    </div>
  </div>
</template>

<script>
export default {
    methods:{
        checkMarket(){
            this.$router.push({
                name:'home'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .explain{
        width: 100%;
      background: #F1F3F7;
      height: 100%;
    }
    .title{
        height: 80px;
        text-align: center;
        line-height: 80px;
        font-size: 30px;
        border-bottom: 1px solid #ccc;
      color: #f6d0ab;
      background: #39394D;
    }
    .content{
        margin: 20px 80px;
      //background: #ffffff;
    }
    .icon{
        width: 140px;
        height: 140px;
        //border-radius: 50%;
        margin: 40px 228px;
        background: url('~@/assets/images/success_black@2x.png') no-repeat;
        background-size: 100% 100%;
    }
    h3{
        color: #1a1a1a;
        font-size: 30px;
        text-align: center;
    }
    .text{
        color: #ccc;
        text-align: center;
        font-size: 28px;
      margin-top: 20px;
      padding-bottom: 100px;
    }
    .checkMarket{
        width: 320px;
      margin: 80px 215px 0;
        padding: 18px 0;
        text-align: center;
        color: #f6d0ab;
        background-color: #39394D;
      border-radius: 40px;
        font-size: 30px;
    }
</style>